.privacy-policy {
  &__wrapper {
    max-width: $large;
    margin: 0 auto;
    width: 90%;
  }

  &__block {
    padding-bottom: 10px;
    width: 100%;

    &:last-child {
      padding-bottom: 50px;
    }
  }

  &__mainTitle {
    @extend .strong-text;
    font-size: 30.4768px;
    font-weight: 700;
    line-height: 48.7629px;
    margin-bottom: 0;
    padding-bottom: 30px;
    text-align: left;
  }

  &__subTitle {
    @extend .strong-text;
    font-size: 18.2861px;
    font-weight: 700;
    line-height: 29.2577px;
    margin: 0;
    padding-bottom: 5px;
    text-align: left;
  }

  &__copy {
    @extend .regular;
    font-size: 13.7146px;
    font-weight: 400;
    line-height: 19.2004px;
    margin: 0;
    padding-bottom: 10px;
    text-align: justify;
  }

  &__link {
    color: $link-blue;

    &:visited {
      color: $link-blue;
    }

    &:hover {
      color: $link-blue-hover;
    }
  }

  &__list:not(.browser-default),  &__subList:not(.browser-default) {
    padding-left: 19px;

    > li {
      position: relative;

      &::before {
        background: $bullet-color;
        border-radius: 50%;
        content: "";
        height: 6px;
        left: -18px;
        position: absolute;
        top: 6px;
        width: 6px;
      }
    }
  }

  &__subList:not(.browser-default) {
    padding-top: 10px;
  }
}