$colors:
  "primary"
    #ffffff
    #00b5dd
    linear-gradient(to right, #00b6de, #00b6de 50%, #552988 100%)
    50%,

  "secondary"
    #552988
    #00b5dd
    linear-gradient(to right, #99edff, #99edff 50%, #5aaaff 100%)
    25%,

    "ternary"
    #ffffff
    #bdab78
    linear-gradient(to right, #bdab78 100%,#bdab78 100%)
    25%;


button.cta, .button.cta {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    cursor: pointer;
    padding: 17px;
    border-radius: 29px;
    border: 0px;
    position: relative;
    z-index: 1;

    @each $name, $text-color-default, $text-color-pressed, $gradient, $displacement in $colors {
        &-#{$name} {
            color: $text-color-default;
            background-image: $gradient;
            background-size: 150% 100%;
            background-position: 100% 0;
            transition: background-position .3s ease-in-out;
            &:hover {
                background-position: $displacement 0;
                transition: background-position .3s ease-in-out;
            }
            &:focus {
                outline: none;
            }
            &:active {
                color: $text-color-pressed;
                &:after {
                    content: '';
                    position: absolute;
                    top: 3px; right: 3px; bottom: 3px; left: 3px;
                    z-index: -1;
                    margin: -5; /* !importanté */
                    border-radius: inherit; /* !importanté */
                    background-color: white;
                }
            }
        }
    }

    &-disable, &:disabled {
        color: white;
        background-color: #dfdfdf;
        background-image: none;
        &:hover, &:active, &:focus {
            color: white;
            background-color: #dfdfdf;
            background-image: none;
            &:after {
                display: none;
            }
        }
    }
}

.round-cta {
  @include round-cta-call();

  @media #{$medium0-design} {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
  }

  @media #{$medium3-design} {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 16px;
  }
}