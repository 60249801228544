.more-positions {
  &__container {
    position: relative;
    transform: rotate(-360deg);
    padding-top: 50px;
    padding-bottom: 60px;
    @media #{$medium0-design} {
      margin: auto;
    }
    &-title {
      width: 95%;
      height: auto;
      transform: rotate(-360deg);
      background-color: $color-cerulean;
      font-size: 35px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 0 3% 4%;
      box-sizing: border-box;
      @media #{$xsmall-design} {
        font-size: 40px;
      }
      @media #{$small-design} {
        font-size: 38px;
        width: 338px;
      }
      @media #{$medium0-design} {
        margin: 0 7% 3% 8%;;
      }
    }
  }
  &__no-results {
    color: $copy-color-site;
    text-align: center;
    width: 100%;
    margin-top: 34%;
    @media #{$medium0-design} {
      margin-top: 10%;
    }
  }
  &__button-container {
    width: 100%;
    margin-bottom: 20%;
    @media #{$medium0-design} {
      margin-top: 3%;
      margin-bottom: 4%;
    }
    button{
      @include round-cta-call(null, $primary-color-site, GothanBold);
      border:0px;
      height: 50px;
    }
  }
  &__load-more {
    width: 263px;
    position: absolute;
    right: 0;
    float: right;
    margin: 5% 5% 0 0;
    @media #{$medium0-design} {
      margin: 0 8% 0 0;
    }
  }
  &__wrapper {
    @media #{$medium0-design} {
      display: flex;
      flex-wrap: wrap;
      width: 88%;
      margin: auto;
    }
  }
  &__content {
    width: 90%;
    height: auto;
    margin: auto auto 5% auto;
    background-color: $primary-color-site;
    padding-top: 7%;
    @media #{$medium0-design} {
      margin: initial;
      flex-basis: 30%;
      padding: 2%;
      margin-right: 2.3%;
      margin-left: 1%;
      max-width: 30%;
      margin-bottom: 25px;
    }
    &-category {
      color: $copy-color-site;
      letter-spacing: 1px;
      font-size: 16px;
      @media #{$medium-design} {
        font-size: 14px;
        letter-spacing: 0.88px;
      }
    }
    &-title {
      color: $copy-color-site;
      font-size: 20px;
      @media #{$xsmall-design} {
        font-size: 25px;
      }
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 1.2;
      letter-spacing: 1.81px;
      @media #{$medium0-design} {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1.44px;
        min-height: 60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &-location {
      display: flex;
      align-items: baseline;
    }
    .position {
      &__icon {
        height: 17px;
        width: 15px;
        align-self: center;
        margin-right: 2px;
        margin-bottom: 6px;
      }
      &__country {
        color: $copy-color-site;
        font-size: 14px;
        @media #{$xsmall-design} {
          font-size: 16px;
        }
        padding-bottom: 7px;
        width: 35%;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: 1px;
        border-bottom: 1px solid $link-color-site;
        @media #{$medium-design} {
          width: 50%;
        }
      }
      &__text {
        margin-bottom: 6%;
        font-size: 14px;
        @media #{$xsmall-design} {
          font-size: 16px;
        }
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.13;
        letter-spacing: 1px;
        word-break: break-word;
        hyphens: auto;
        //box elements vertical alignment
        @media #{$medium0-design} {
          line-height: 1.33;
          letter-spacing: 1.13px;
          word-break: break-word;
          hyphens: auto;
          min-height: 235px;
        }
        @media #{$medium1-design} {
          min-height: 190px;
        }
        @media #{$medium3-design} {
          min-height: 170px;
        }
        @media #{$medium4-design} {
          min-height: 130px;
        }
      }
      &__link {
        margin-top: 10px;
        a {
          font-size: 20px;
          @media #{$xsmall-design} {
            font-size: 25px;
          }
          @media #{$medium0-design} {
            font-size: 16px;

            &:hover {
              color: $link-color-site-hover;
              text-decoration: underline;
            }
          }
          font-weight: 900;
          color: $link-color-site;
        }
      }
    }
  }
}
