@font-face {
    font-family: 'GothanBold';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Gotham-Bold.otf') format('opentype');
    src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  }

@font-face {
    font-family: 'GothanBook';
    src: url('./fonts/Gotham-Book.ttf') format('opentype');
    src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham-Black';
    src: url('./fonts/Gotham-Black.otf') format('opentype');
    src: url('./fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'GothanMedium';
    src: url('./fonts/Gotham-Medium.otf') format('opentype');
    src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'GothanBold';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bold {
    font-family: 'GothanBold';
}

.regular {
    font-family: 'GothanBook';
}

.medium {
    font-family: 'GothanMedium';
}

.strong-text {
    font-family: 'Gotham-Black';
}
