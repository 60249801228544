@mixin round-cta-call($background: null,$text-color: null, $font-family: null) {
  border: 1px solid $primary-color-dark-site;
  border-radius: 25px;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: -0.16px;
  line-height: 16px;
  padding: 8px 12px;
  text-align: center;
  z-index: 1;

  $list-parameters: (
    0: (default-value: $color-gimblet, parameter-value: $background, property-name: background-color),
    1: (default-value: $primary-color-dark-site, parameter-value: $text-color, property-name: color),
    2: (default-value: GothanBook, parameter-value: $font-family, property-name: font-family)
  );

  @each $index, $case in $list-parameters {
    $current-property-name: map-get($case, property-name);
    $current-parameter-value: map-get($case, parameter-value);

    @if($current-parameter-value) {
      #{$current-property-name}: $current-parameter-value;
    } @else {
      #{$current-property-name}: map-get($case, default-value);
    }
  }
}