.hot-positions {
  &__no-results {
    text-align: center;

    &-title {
      color: $copy-color-site;
      font-size: 30px;

      @media #{$xsmall-design} {
        font-size: 36px;
      }

      @media #{$small-design} {
        font-size: 42px;
      }
    }

    &-content {
      color: $copy-color-site;
      font-size: 22px;
      margin-top: 8px;
      margin-bottom: 150px;
    }
  }

  &__glass-icon {
    height: 258px;
    width: 285px;
    display: block;
    margin: 67px auto 67px auto;

    @media #{$small0-design} {
      margin: auto auto 49px auto;
    }
  }

  &__router-icon {
    height: 192px;
    width: 295px;
    display: block;
    margin: 67px auto 67px auto;

    @media #{$small0-design} {
      margin: auto auto 49px auto;
    }
  }

  &__container {
    position: relative;
    padding-top: 50px;
    
    @media #{$medium-design} { 
      width: 100%;
      margin-bottom: 60px;
      padding-bottom: 60px;
    }

    &-title {
      font-family: 'Gotham-Black';
      width: 200px;
      font-size: 49px;
      font-weight: 900;
      color: $primary-color-site;
      margin: 60px 7% 9% 7%;
      line-height: 1.08;

      @media #{$small-design} {
        font-size: 48px;
        width: 395px;
      }
    }
    &-bg {
      z-index: -1;
      position: absolute;
      bottom: auto;
      height: 100%;
      width: max-content;
      overflow: hidden;
      @media #{$xsmall-design} { 
        width: 100%;
      }
      
      img {
        width: 100%;
        height: auto;

        @media #{$xsmall-design} { 
          height: 100%;
        }

        @media #{$large-design} {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  &__overlap {
    display: none;

    @media #{$medium3-design} {
      display: inline;
      position: absolute;
      right: 0;
      top: -63px;
      margin-right: 8%;
    }
  }

  &__wrapper {

    @media #{$medium0-design} {
      display: flex;
      flex-wrap: wrap;
      width: 88%;
      margin: auto;
    }
  }

  &__loading-results-img {
    margin: 40px 0 120px 0;

    @media #{$medium0-design} {
      margin: 0 0 140px 0;
    }
  }

  &__content {
    width: 90%;
    height: auto;
    margin: auto auto 11% auto;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $primary-color-site;
    padding: 7% 7% 4% 7%;

    @media #{$medium0-design} {
      margin: initial;
      flex-basis: 30%;
      padding: 2%;
      margin-right: 2.3%;
      margin-left: 1%;
      max-width: 30%;
    }

    &-category {
      font-size: 16px;
      line-height: normal;
      letter-spacing: 1px;
      color: $light-gray-color-site;
      font-family: 'Gotham-Black';

      @media #{$medium-design} {
        font-size: 14px;
        letter-spacing: 0.88px;
      }
    }

    &-title {
      color: $copy-color-site;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 1.2;
      letter-spacing: 1.81px;

      @media #{$xsmall-design} {
        font-size: 25px;
      }

      @media #{$medium0-design} {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1.44px;
        min-height: 60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; 
      }
      
    }
    &-location {
      display: flex;
      align-items: baseline;
    }    
    .position {

      &__icon {
        height: 17px;
        width: 15px;
        align-self: center;
        margin-right: 2px;
        margin-bottom: 6px;
      }

      &__country {
        color: $copy-color-site;
        font-size: 14px;
        padding-bottom: 7px;
        width: 35%;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: 1px;
        border-bottom: 1px solid $link-color-site;

        @media #{$xsmall-design} {
          font-size: 16px;
        }

        @media #{$medium-design} {
          width: 50%;
        }
      }

      &__text {
        margin-bottom: 8%;
        font-size: 14px;
        color: $copy-color-site;
        line-height: 2.13;
        letter-spacing: 1px;

        @media #{$xsmall-design} {
          font-size: 16px;
        }

        //box elements vertical alignment
        @media #{$medium0-design} {
          line-height: 1.38;
          letter-spacing: 1px;
          word-break: break-word;
          hyphens: auto;
          min-height: 235px;
        }

        @media #{$medium2-design} {
          min-height: 190px;
        }
        
        @media #{$medium3-design} {
          min-height: 170px;
        }

        @media #{$medium4-design} {
          min-height: 130px;
        }
      }
      &__link {
        margin-top: 10px;

        a {
          font-size: 20px;

          @media #{$xsmall-design} {
            font-size: 25px;
          }

          @media #{$medium0-design} {
            font-size: 16px;

            &:hover {
              color: $link-color-site-hover;
              text-decoration: underline;
            }
          }

          font-weight: 900;
          color: $link-color-site;
        }
      }
    }
  }
}
