.careers {
  
  &__search-wrapper {
    @media #{$medium2-design} {
      max-width: 902px;
      margin: auto;
    }
  }

  &__search {

    padding-right: 50px;
    @media #{$small-design} {
      padding-right: 0px;
    }
    @media #{$medium-design} {
      padding-right: 80px;
    }
    @media #{$medium0-design} {
      padding-right: 0px;
    }

    .careers__search-content {
      .search__container {
        position: relative;

        &-item {
          color: $copy-color-site;
          height: 60px;
          width: 100%;
          border-style: solid;
          text-indent: 10px;
          border-radius: 5px;
          border: solid 1px $select-border-color;
          background-color: $select-background-color;
          font-size: 18px;
          line-height: 2.22;
          letter-spacing: 1.3px;
          outline-offset: 0;
          @media #{$medium-design} {
            height: 58px;
          }

          &:not(.browser-default):focus:not([readonly]) {
            box-shadow: initial;
          }

          &:focus {
            border-color: $input-focus-color;
            background: $primary-color-site;
          }
        }

        &::placeholder {
          text-indent: 11px;
          color: $select-color-text;
        }

        &::after {
          content: "";
          height: 35px;
          width: 35px;
          background: url("./img/searchIco.png") no-repeat;
          position: absolute;
          top: 45%;
          transform: translate(-50%, -50%);
          right: 0%;
        }
      }
    }
    .selectedCity{
      color: #000;
      text-align: left;
      letter-spacing: 0px;
      top: -1px;
      position: relative;
      margin: 0px 23px 0px 10px;
      @media #{$xsmall-design} {
        margin-right: 69px;
      }
      @media #{$small-design} {
        margin-right: 116px;
      }
      @media #{$small0-design} {
        margin-right: 158px;
      }
      @media #{$medium-design} {
        margin: 0px 10px;
      }
    }
  }
  .locationTitle{
    margin-top: 10px;
  }
  &__selector {
    @media #{$medium-design} {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-size: 22px;
      color: $copy-color-site;
    }

    &-item {
      color: $copy-color-site;
      width: 100%;
      display: block;
      border-style: solid;
      border-radius: 5px;
      border: solid 1px $select-border-color;
      height: 60px;
      line-height: 2.22;
      letter-spacing: 1.3px;
      font-size: 18px;
      margin-top: 10px;
      appearance: none;
      padding: 0 24px 0 19px;
      background: url("./img/triangle.png") no-repeat $select-background-color;
      background-size: auto;
      background-position: 93%;
      @media #{$medium-design} {
        height: 58px;
      }
      &:focus {
        outline: none;
      }
    }

    &-location {
      margin-top: 3%;
      margin-right: 35px;
      br {
        display: none;
      }
      @media #{$xsmall-design} {
        width: 100%;         
      }
      @media #{$medium-design} {
        width: 160px;
        br {
          display:inherit;
        }
      }
      @media #{$medium0-design} {
        width: 157px;
      }
      @media #{$medium1-design} {
        width: 137px;
      }
      @media #{$medium2-design} {
        width: 133px;
      }
      @media #{$medium5-design} {
        width: 117px;
      }
    }

    &-job {
      margin-top: 3%;
      @media #{$medium-design} {
        justify-content: flex-end;
        width: 75%;
      }
    }
  }
}
