// Colors
// ==========================================================================

$primary-color-site: #ffffff;
$primary-color-light-site: #ffffff;
$primary-color-dark-site: #000000;

$secondary-color-dark-site: #212129;
$secondary-color-site: #ffffff;
$success-color-site: #ffffff;
$error-color-site: #ffffff;
$link-color-site: #BAAC7E;
$link-color-site-hover: #003c8a;
$link-blue: #100af2;
$link-blue-hover: #0056b3;
$light-gray-color-site: #484747;
$mid-gray-color-site: #4d4d4d;
$copy-color-site: #383838;
$select-color-text: #646464;
$select-background-color: #fafbfc;
$select-border-color:#b0b8ba;
$input-color-text: #a5a5a5;
$primary-red-light-color: #ef4135;
$dots-gray-color: #c0c0c0;
$title-red-light-color: #b81b09;
$bullet-color: #333;

$color-black-medium: #212128;
$color-cerulean: #0ebac5;
$color-silver: #cccccc;
$color-crusta: #fc8b3c;
$color-orange-site: #FC8B3C;
$color-gimblet: #bdab78;
$color-royal-purple: #6f359e;

$nav-home-background-color: rgba(0, 0, 0, 0.3);
$nav-default-background-color: #ffffff;

$mobile-menu-border-color: #979797;
$mobile-menu-background-color: #ffffff;
$mobile-menu-text-color: #000000;

$desktop-menu-text-color-home: #ffffff;
$desktop-menu-text-color-default: #000000;

$input-focus-color: #00b6de;
$input-valid-color: #8dc63f;
$input-invalid-color: #ef4135;

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

// Nav Animation
// ==========================================================================

$initialHeaderAnimation: 0px;
$endHeaderAnimation: 6px;
$endHeaderAnimationMobile: 3px;

// Mixins
// ==========================================================================

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin aspect() {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
  }

  > .content {
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin absolute-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Media Query Ranges
// ==========================================================================

$small-mini-screen: 321px !default;
$extra-extra-screen-up: 1441px !default;

$small-mini-and-down: "only screen and (max-width : #{$small-mini-screen})" !default;
$small-section-only: "only screen and (min-width : #{$small-mini-screen}) and (max-width : #{$small-screen})" !default;
$extra-extra-large-and-up: "only screen and (min-width : #{$extra-extra-screen-up})" !default;

$xsmall: 375px;
$small: 500px;
$small0: 600px;
$medium: 641px;
$medium0: 768px;
$medium1: 900px;
$medium2: 980px;
$medium3: 1024px;
$medium4: 1150px;
$medium5: 1280px;
$large: 1440px;

$xsmall-design: "screen and (min-width : #{$xsmall})";
$small-design: "screen and (min-width : #{$small})";
$small0-design: "screen and (min-width : #{$small0})";
$medium-design: "screen and (min-width : #{$medium})";
$medium0-design: "screen and (min-width : #{$medium0})";
$medium1-design: "screen and (min-width : #{$medium1})";
$medium2-design: "screen and (min-width : #{$medium2})";
$medium3-design: "screen and (min-width : #{$medium3})";
$medium4-design: "screen and (min-width : #{$medium4})";
$medium5-design: "screen and (min-width : #{$medium5})";
$large-design: "screen and (min-width : #{$large})";
