.home{
    &__wrapper{
        &-content{
            margin: 60px auto 50px;
            padding: 0 20px;

            @media #{$small-design} {
                margin: 70px auto 60px;
            }
            @media #{$small0-design} {
                margin: 130px auto 150px;
            }
            @media #{$medium-design} {
                display: flex;
                margin: 74px auto 80px;
                max-width: 1050px;
            }
            @media #{$medium4-design} {
                max-width: 1150px;
                padding: 0;
            }
        }
    }
    &__title {
        align-self: center;
        color: $color-cerulean;
        display: flex;
        flex-direction: column;
        font-size: 40px;
        line-height: 1;
        text-align: left;
        text-transform: uppercase;
        width: 90%;
        @media #{$medium0-design} {
            font-size: 50px;
            text-align: right;
        }
        @media #{$medium1-design} {
            font-size: 60px;
        }
       &-bold {
        background-clip: text;
        color: $secondary-color-dark-site;
        font-family: Gotham-Black;
        font-size: 50px;
        font-weight: 900;
        line-height: 1;
        @media #{$medium1-design} {
            font-size: 65px;
        }
        @media #{$medium2-design} {
            font-size: 75px;
        }
       }
    }
    &__lineDivider {
        display: none;
        @media #{$medium-design} {
            display: block;
            width: 1px;
            height: 250px;
            border-right: solid 1px $color-silver;
            margin: 0 20px 0 20px;
        }
        @media #{$medium1-design} {
            height: 314px;
            margin: 0 25px 0 25px;
        }
        @media #{$medium3-design} {
            margin: 0 50px 0 50px;
        }
        @media #{$medium4-design} {
            margin: 0 65px 0 65px;
        }
    }
    &__subtitle {
        color: $secondary-color-dark-site;
        font-size: 25px;
        letter-spacing: 0;
        line-height: 1.2;
        margin-top: 20px;
        width: 100%;
        @media #{$xsmall-design} {
            margin-top: 25px;
            width: 85%;
        }
        @media #{$small-design} {
            font-size: 18px;
            margin-top: 30px;
        }
        @media #{$small0-design} {
            font-size: 25px;
        }
        @media #{$medium-design} {
            margin-top: 0;
            align-self: center;
            line-height: 1.575;
        }
        @media #{$medium0-design} {
            font-size: 26px;
            width: 100%;
        }
        @media #{$medium1-design} {
            font-size: 30px;
        }
        @media #{$medium2-design} {
            font-size: 35px;
        }
    }
}