.about {
    &__masthead {
      display: flex;
      flex-direction: column;

      .abs-dotted {
        position: absolute;
        width: 92%;
        height: 50%;
        margin-left: 27px;
        margin-top: 0;
        z-index: 1;

        @media #{$small-design} {
          margin-top: 57px;
          height: 60%;
        }

        @media #{$medium0-design} {
          height: 428px;
          width: 500px;
        }

        @media #{$medium5-design} {
          height: 593px;
          margin-top: 40px;
          width: 646px;
        }

        @media #{$large-design} {
          margin-left: 87px;
        }
      }

      @media #{$medium0-design} {
          position: relative;
          padding-bottom: 90px;
      }

      &-container {
          color: $primary-color-site;
          background-color: $color-orange-site;
          padding: 87px 48px 30px 21px;
          overflow: hidden;
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;

          .about-cta {
            display: flex;
            z-index: 1;
            height: 45px;
            width: 125px;
            justify-content: center;
            align-items: center;
            margin-top: 32px;
            @include round-cta-call($secondary-color-dark-site, $color-gimblet, GothanBold);
          }

          @media #{$small-design} {
              padding: 70px 58px 68px 43px;
          }

          @media #{$small0-design} {
              height: 422px;
              padding: 86px 78px 0 36px;
          }

          @media #{$medium-design} {
              height: 465px;
          }

          @media #{$medium0-design} {
              width: 60%;
              height: 426px;
              padding: 81px 165px 0 30px;
              top: 38px;

              .about-cta {
                height: 50px;
                width: 130px;
              }
          }

          @media #{$medium1-design} {
              height: 440px;
          }

          @media #{$medium4-design}{
              width: 831px;
              height: 602px;
              padding: 97px 360px 0 40px;
          }

          @media #{$large-design}{
              padding: 107px 170px 0 72px;
          }
      }
      &-bg {
        position: relative;
        right: 0;
        z-index: 1;

        picture img {
          float: right;
          width: 100%;
          z-index: 1;

          @media #{$medium0-design} {
            width: 470px;
          }

          @media #{$medium1-design} {
            width: 507px;
          }

          @media #{$medium4-design} {
            width: 697px;
          }
        }
      }

      @media #{$medium0-design} {
        flex-direction: row;

        &-bg {
          position: absolute;
        }
      }
    }
    &__title{
        $hard-code-px: 33px;
        font-size: 23px;
        font-style: italic;
        line-height: 1.1;
        letter-spacing: 1px;
        position: relative;
        margin: 0;
        padding-left: 0;
        border-left: 0;
        z-index: 1;

        @media #{$xsmall-design}{
          font-size: 26px;
        }

        @media #{$small0-design} {
            font-size: 30px;
        }
        @media #{$medium-design} {
            font-size: 36px;
        }
        @media #{$medium0-design} {
            margin: 0;
            font-size: 19px;
            line-height: 1.2;

            &:before, &:after {
              height: 33px;
              width: 55px;
              background-size: 29px $hard-code-px;
            }

            &:before {
              top: -33px;
              left: 0;
            }

            &:after {
              bottom: -33px;
              left: -2px;
            }
        }
        @media #{$medium1-design} {
            font-size: 23px;

            &:before, &:after {
              background-size: 36px $hard-code-px;
            }

            &:before {
              top: -39px;
            }

            &:after {
              bottom: -39px;
            }
        }

        @media #{$medium3-design} {
          font-size: 25px;

          &:before, &:after {
            background-size: 42px $hard-code-px;
          }
        }

        @media #{$medium5-design} {
            font-size: 32px;

            &:before, &:after {
              background-size: 55px $hard-code-px;
            }
        }
        @media #{$large-design} {
            font-size: 35px;
        }
    }

    &__author {
      font-size: 20px;
      letter-spacing: normal;
      color: $primary-color-site;
      margin-top: 60px;

      @media #{$small-design}{
          font-size: 20px;
      }

      @media #{$medium0-design} {
          margin-top: 55px;
      }

      @media #{$medium1-design} {
        margin-top: 67px;
        font-size: 25px;
      }

      @media #{$medium3-design}{
        font-size: 30px;
      }
    }
}
