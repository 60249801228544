@mixin dotter-bg-mixin(
  $dot-color,
  $bgPositionDesktop: -48px -19px,
  $bgSize: 106px 110px,
  $bgPositionMobile: -32px -19px
) {
  background-image: radial-gradient($dot-color 3%, rgba(255, 255, 255, 0) 5%);
  background-position: $bgPositionDesktop;
  background-size: $bgSize;

  @media #{$small-and-down} {
    background-position: $bgPositionMobile;
  }
}
