.offering{
    &__container{
        padding: 8px 5% 0 5%;
        @media #{$xsmall-design}{
            padding: 10px 5% 0 5%;
        }
        @media #{$small-design}{
            padding: 60px 5% 0 5%;
        }
        @media #{$small0-design}{
            padding: 23px 5% 0 5%;
        }
        @media #{$medium-design}{
            padding: 28px 9% 0 9%;
        }
        @media #{$medium0-design}{
            padding: 48px 5% 0 5%;
        }
        @media #{$medium1-design}{
            padding: 36px 5% 0 5%;
        }
        @media #{$medium2-design}{
            padding: 105px 5% 0 5%;
        }
        @media #{$medium3-design}{
            padding: 85px 5% 0 5%;
        }
        @media #{$large-design}{
            padding: 85px 10% 0 10%;
        }
    }
    &__wrapper{
        &-titles{
            padding-bottom: 50px;
            @media #{$xsmall-design}{
                padding-bottom: 60px;
            }
            @media #{$small0-design}{
                padding-bottom: 100px;
            }
            @media #{$medium0-design}{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 82px;
            }
            @media #{$medium1-design}{
                padding-bottom: 70px;
            }
            @media #{$medium2-design}{
                padding-bottom: 64px;
            }
            @media #{$medium3-design}{
                padding-bottom: 118px;
            }
            .offering{
                &__title{
                    text-transform: uppercase;
                    margin: 0 0 20px 0;
                    @media #{$xsmall-design}{
                        margin: 0 0 30px 0;
                    }
                    @media #{$small0-design}{
                        margin: 0 0 48px 0;
                    }
                    @media #{$medium0-design}{
                        margin: 0 0 0 7%;
                       justify-content: flex-start;
                    }
                    &-p{
                        margin: 0;
                        background-image: linear-gradient(90deg, #0EBAC5, #0EBAC5);
                        line-height: 1.23;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 30px;
                        @media #{$xsmall-design}{
                            font-size: 36px;
                            font-family: 'Gotham-Black';
                        }
                        @media #{$small-design}{
                            font-size: 48px;
                            font-family: 'Gotham-Black';
                        }
                        @media #{$small0-design}{
                            font-size: 57px;
                            font-family: 'Gotham-Black';
                        }
                        @media #{$medium0-design}{
                            background-image: linear-gradient(90deg, #0EBAC5, #0EBAC5);
                            line-height: 1;
                            font-size: 37px;
                            font-family: 'GothanMedium';
                        }
                        @media #{$medium1-design}{
                            font-size: 47px;
                        }
                        @media #{$medium2-design}{
                            font-size: 52px;
                        }
                        @media #{$medium3-design}{
                            font-size: 58px;
                        }
                        @media #{$medium4-design}{
                            font-size: 65px;
                            max-width: $medium;
                        }
                        @media #{$medium5-design}{
                            font-size: 45px;
                            max-width: 670px;
                        }
                    }
                }
                &__subtitle{
                    color: $copy-color-site;
                    line-height: 1.14;
                    font-size: 16px;
                    @media #{$xsmall-design}{
                        font-size: 20px;
                    }
                    @media #{$small-design}{
                        font-size: 30px;
                    }
                    @media #{$small0-design}{
                        font-size: 35px;
                    }
                    @media #{$medium0-design}{
                        justify-content: flex-end;
                        max-width: $xsmall;
                        font-size: 20px;
                        align-self: center;
                    }
                    @media #{$medium1-design}{
                        font-size: 25px;
                    }
                    @media #{$medium4-design}{
                        font-size: 30px;
                    }
                    @media #{$medium5-design}{
                        max-width: 400px;
                        font-size: 35px;
                    }
                    &-bold{
                        color: $copy-color-site;
                        font-weight: 500;
                        font-size: 16px;
                        @media #{$xsmall-design}{
                            font-size: 20px;
                        }
                        @media #{$small-design}{
                            font-size: 30px;
                        }
                        @media #{$small0-design}{
                            font-size: 35px;
                        }
                        @media #{$medium0-design}{
                            font-size: 20px;
                        }
                        @media #{$medium1-design}{
                            font-size: 25px;
                        }
                        @media #{$medium4-design}{
                            font-size: 30px;
                        }
                        @media #{$medium5-design}{
                            font-size: 35px;
                        }
                    }
                }
            }
        }
        &-content{
            @media #{$small-design}{
                padding-bottom: 40px;
            }
            @media #{$small0-design}{
                padding-bottom: 50px;
            }
            @media #{$medium0-design}{
                display: flex;
                padding-bottom: 55px;
            }
            @media #{$medium2-design}{
                padding-bottom: 120px;
            }
            .offering{
                &__info{
                    &-container{
                        padding: 0 5% 34px 5%;
                        @media #{$xsmall-design}{
                            padding: 0 5% 40px 5%;
                        }
                        @media #{$small-design}{
                            padding: 0 5% 50px 5%;
                        }
                        @media #{$small0-design}{
                            padding: 0 5% 68px 5%;
                        }
                        @media #{$medium0-design}{
                            padding: 0 2% 68px 2%;
                            width: 100%;
                        }
                        @media #{$medium2-design}{
                            padding: 0 2% 0 2%;
                        }
                        .icon{
                            &__tech{
                                @media #{$medium0-design}{
                                    padding-bottom: 48px;
                                }
                                @media #{$medium2-design}{
                                    padding-bottom: 45px;
                                }
                            }
                            &__creative{
                                @media #{$medium0-design}{
                                    padding-bottom: 56px;
                                }
                                @media #{$medium2-design}{
                                    padding-bottom: 54px;
                                }
                            }
                            &__media{
                                @media #{$medium0-design}{
                                    padding-bottom: 80px;
                                }
                                @media #{$medium2-design}{
                                    padding-bottom: 78px;
                                }
                            }
                        }
                        .text{
                            &__tech{
                                ul{
                                    @media #{$medium0-design}{
                                        padding-left: 14%;
                                    }
                                    @media #{$medium1-design}{
                                        padding-left: 16%;
                                    }
                                    @media #{$medium3-design}{
                                        padding-left: 18%;
                                    }
                                    @media #{$medium4-design}{
                                        padding-left: 20%;
                                    }
                                }
                            }
                            &__creative{
                                ul{
                                    @media #{$medium0-design}{
                                        padding-left: 14%;
                                    }
                                    @media #{$medium3-design}{
                                        padding-left: 12%;
                                    }
                                    @media #{$medium4-design}{
                                        padding-left: 16%;
                                    }
                                    @media #{$medium5-design}{
                                        padding-left: 14%;
                                    }
                                }
                            }
                            &__media{
                                ul{
                                    @media #{$medium0-design}{
                                        padding-left: 14%;
                                    }
                                    @media #{$medium1-design}{
                                        padding-left: 22%;
                                    }
                                    @media #{$medium3-design}{
                                        padding-left: 24%;
                                    }
                                    @media #{$medium4-design}{
                                        padding-left: 24%;
                                    }
                                }
                            }
                        }
                    }
                    &-icon{
                        text-align: center;
                        padding-bottom: 20px;
                        @media #{$small0-design}{
                            padding-bottom: 15px;
                        }
                    }
                    &-title{
                        font-size: 28px;
                        text-transform: uppercase;
                        font-weight: 500;
                        line-height: 1.33;
                        text-align: center;
                        color: $copy-color-site;
                        padding-bottom: 8px;
                        @media #{$xsmall-design}{
                            font-size: 26px;
                            padding-bottom: 5px;
                        }
                        @media #{$small-design}{
                            font-size: 32px;
                            padding-bottom: 0;
                        }
                        @media #{$small0-design}{
                            font-size: 38px;
                        }
                        @media #{$medium0-design}{
                            font-size: 25px;
                        }
                        @media #{$medium1-design}{
                            font-size: 26px;
                        }
                        @media #{$medium2-design}{
                            font-size: 30px;
                        }
                        @media #{$medium3-design}{
                            font-size: 32px;
                        }
                        @media #{$medium5-design}{
                            font-size: 35px;
                        }
                    }
                    &-text{
                        font-size: 14px;
                        line-height: 2.13;
                        letter-spacing: 1.16px;
                        color: $copy-color-site;
                        @media #{$xsmall-design}{
                            font-size: 16px;
                        }
                        @media #{$medium0-design}{
                            padding-top: 46px;
                        }
                        @media #{$medium2-design}{
                            padding-top: 36px;
                        }
                        @media #{$medium3-design}{
                            padding-top: 38px;
                        }
                        @media #{$large-design}{
                            padding-top: 34px;
                        }
                        ul{
                            list-style-type: disc;
                            padding-left: 6%;
                            @media #{$xsmall-design}{
                                padding-left: 8%;
                            }
                            @media #{$small0-design}{
                                padding-left: 11%;
                            }
                        }
                        li{
                            list-style-type: inherit;
                        }
                    }
                }
            }
        }
    }
}