@import "./AboutMainHero/AboutMainHero.module";
@import "./MagicMakerContent/MagicMakerContent.module";
@import "./PublicisHero/PublicisHero.module";
@import "./OfferingSolutions/OfferingSolutions.module";
@import "./MakingImpossible/MakingImpossible.module";
@import "./LogoGrid/LogoGrid.module";

.about{
    &__wrapper{
        max-width: $large;
        margin: 0 auto;
    }
}