@import "./components/Events/EventsHero/_EventsHero.module";
@import "./components/Events/EventCard/_EventCard.module";
@import "./components/Events/Event/_Event.module";
@import "./components/Events/Event/EventInfo/_EventInfo.module";

.events {
  &__wrapper {
    max-width: $large;
    margin: 0 auto;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px auto;
    width: 90%;

    @media #{$medium-design} {
      margin: 35px auto 70px;
    }
  }
}
