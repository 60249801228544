@import "./components/Careers/CareersHero/_CareersHero.module";
@import "./components/Careers/CareersSearch/CareersSearch.module";
@import "./components/Careers/OpenPositions/HotPositions/HotPositions.module";
@import "./components/Careers/OpenPositions/MorePositions/MorePositions.module";

.careers {
  &__wrapper {
    min-width: 375px;
    max-width: $large;
    margin: 0 auto;
  }
}