.thanks{
    &__masthead{
        @media #{$medium0-design}{
            position: relative;
            padding-bottom: 90px;
        }
        &-container{
            color: $primary-color-site;
            background-image: linear-gradient(237deg, #086b2e, #8dc63f);
            padding: 45px 10% 30px 10%;       
            overflow: hidden;
            @media #{$small-design}{
                padding: 70px 10% 68px 10%;
            }
            @media #{$small0-design}{
                height: 422px;
                padding: 70px 10% 0 10%;
            }
            @media #{$medium-design}{
                height: 465px;
            }
            @media #{$medium0-design}{
                width: 60%;
                height: 402px;
                padding: 120px 12% 0 5%
            }
            @media #{$medium1-design}{
                height: 480px;
                padding: 140px 14% 0 4%;
            }
            @media #{$medium2-design}{
                padding: 140px 17% 0 4%;
            }
            @media #{$medium4-design}{
                padding: 140px 18% 0 3%;
                width: 660px;
            }
            @media #{$medium5-design}{
                width: 759px;
                height: 602px;
                padding: 165px 18% 0 3%;
            }
            @media #{$large-design}{
                margin-left: 74px;
                padding: 165px 170px 0 5%;
            }
        }
        &-bg{
            background: url('./img/bg__thank-you-mobile.png') no-repeat;
            width: 100%;    
            height: 311px;
            background-position: 60% 70%;
            @media #{$xsmall-design}{
                background-position: 60% 70%;
            }
            @media #{$small-design}{
                height: 411px;
                background-position: 50% 0;
            }
            @media #{$medium-design}{
                background-size: cover;
                background-position: 100% 70%;
            }
            @media #{$medium0-design}{
                background: url('./img/bg__thank-you-desktop.png') no-repeat;
            }
            @media #{$medium0-design}{
                width: 50%;
                position: absolute;
                top: 38px;
                right: 0;
                height: 410px;
                background-position: center;
            }
            @media #{$medium1-design}{
                height: 480px;
            }
            @media #{$medium2-design}{
                width: 55%;
            }
            @media #{$medium4-design}{
                width: 660px;
            }
            @media #{$medium5-design}{
                width: 702px;
                height: 610px;
            }
        }
    }
    &__title{
        font-size: 25px;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        position: relative;
        margin: 0px 0 0 20%;
        padding-left: 0;
        border-left: 0;
        font-weight: bold;
        @media #{$xsmall-design}{
            margin: 0 0 0 40px;
            font-size: 40px;
        }
        @media #{$small-design}{
            font-size: 34px;
        }
        @media #{$small0-design}{
            font-size: 40px;
        }
        @media #{$medium-design}{
            font-size: 46px;
        }
        @media #{$medium0-design}{
            margin: 0;
            font-size: 32px;
            line-height: 1.2;
        }
        @media #{$medium1-design}{
            font-size: 36px;
        }
        @media #{$medium3-design}{
            font-size: 38px;
        }
        @media #{$medium5-design}{
            font-size: 46px;
        }
        @media #{$large-design}{
            font-size: 65px;
        }         
    }
    &__subtitle h2#{&}__subtitle-text{
        font-size: 20px;
        color: $primary-color-site;
        float: right;
        margin-top: 18px;
        line-height: 1.4;
        @media #{$small-design}{
            font-size: 35px;            
        }
        @media #{$medium0-design}{
            margin-top: 20px;            
        }
    }
    &__subtitle h2:first-child#{&}__subtitle-text {
        margin-bottom: 0px;
    }
    &__subtitle h2:nth-child(2)#{&}__subtitle-text {
        margin-top: 0px;
    }

}
