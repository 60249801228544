.careers {
  &__hero {
    .hero {
      &__masthead {
        margin-bottom: -57px;
        @media #{$medium-design} {
          margin-bottom: -50px;
        }
        @media #{$medium3-design} {
          margin-bottom: 100px;
        }
       
        &-bg {
          background-size: cover;
          &-responsive{
            margin-bottom: -6px;
            @media #{$medium-design} {
              height: auto;
              width: 325px;
              margin-bottom: 0px;
            }
            @media #{$medium0-design} {
              height: auto;
              width: 385px;
            }
            @media #{$medium1-design} {
              width: 475px;
            }
            @media #{$medium3-design} {
              width: 525px;
            }
            @media #{$medium4-design} {
              width: 500px;
            }
            @media #{$medium5-design} {
              width: 50%;
            }
          }
        }

        &-container {
          background-color: $color-cerulean;
          z-index: 0;
          @media #{$medium-design} {
            padding: 70px 3% 320px 10%;
          }
          @media #{$medium1-design} {
            padding: 115px 8% 2px 12%;
          }
          @media #{$medium5-design} {
            padding: 115px 6% 2px 11%;
          }
          @media #{$large-design} {
            padding: 115px 6% 2px 8%;
            width: 769px;
          }
          &-responsive::before{
            @extend .dotted-bg__cyan;
            z-index: -1;
          }
        }
        
        .masthead__title{
          line-height: 0.8;
          max-width: 100%;
          font-size: 57px;
          &-bold{
            font-size: 48px;
          }
          
          @media #{$small-design} {
            font-size: 74px;
            &-bold{
              font-size: 62px;
            }
          }
          @media #{$small0-design} {
            font-size: 90px;
            &-bold{
              font-size: 75px;
            }
          }
          @media #{$medium-design} {
            font-size: 51px;
            &-bold{
              font-size: 43px;
            }
          }
          @media #{$medium0-design} {
            font-size: 52px;
            &-bold{
              font-size: 44px;
            }
          }
          @media #{$medium1-design} {
            font-size: 61px;
            &-bold{
              font-size: 51px;
            }
          }
          @media #{$medium2-design} {
            font-size: 67px;
            &-bold{
              font-size: 56px;
            }
          }
          @media #{$medium3-design} {
            font-size: 70px;
            &-bold{
              font-size: 58px;
            }
          }
          @media #{$medium4-design} {
            font-size: 79px;
            &-bold{
              font-size: 66px;
            }
          }
          @media #{$medium5-design} {
            font-size: 95px;
            &-bold{
              font-size: 79px;
            }
          }
          @media #{$large-design} {
            font-size: 104px;
            &-bold{
              font-size: 87px;
            }
          }
        }
      }
    }
  }
}
