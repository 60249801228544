@import "../variables.scss";

.navigation {
  box-shadow: none;
  height: auto;
  position: fixed;
  top: 0;
  z-index: 20;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;


  &__home {
    background-color: transparent;
  }

  &__default {
    background-color: $nav-default-background-color;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
  }

  &-hidden {
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }

  &-show {
    opacity: 1;
    transform: translateY(0%);
    transition: all 0.3s ease-in-out;
  }

  &__wrapper {

    &__home {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &-mobile {
      justify-content: flex-end;
      align-self: center;

      @media #{$medium-design} {
        display: none;
      }
    }

    &-desktop {
      justify-content: flex-end;
      padding-top: 37px;
      display: none;

      @media #{$medium-design} {
        display: flex;
        padding: 0 0 0 10px;
        flex-grow: 1;
      }
    }
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 80px;
    max-width: $large;
    padding-right: 14px;

    @media #{$medium-design} {
      padding-right: 0;
    }
  }

  &__logo {
    justify-content: flex-start;
    padding-bottom: 2px;

    @media #{$medium0-design} {
      margin-left: 5%;
    }

    &-link {
      display: inline-block;
    }

    &-image {
      @extend .base-pg-logo;
    }
  }

  &__mobile {
    &-image {
      width: 33px;
      height: 23px;
      vertical-align: middle;
    }
  }
}

.desktop {
  &__menu {
    &__home {
      align-items: center;
      color: $desktop-menu-text-color-home;
      display: flex;
    }

    &__default {
      @extend .desktop__menu__home;
      color: $desktop-menu-text-color-default;
    }

    &-item {
      line-height: normal;
      padding: 0 18px;

      @media #{$medium0-design} {
        padding: 0 34px;
      }

      a {
        @media #{$medium-design} {
          border-width: 0;
          padding: 8px 0;
        }

        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;

        &.active-page {
          border-width: 0 0 3px 0;
        }
      }

      &__home {
        a {
          color: $desktop-menu-text-color-home;
          &:hover{
            background-color: transparent;
          }
        }
      }

      &__default {
        a {
          color: $desktop-menu-text-color-default;
        }

        :hover {
          background-color: $primary-color-light-site;
        }
      }
    }

    &-line {
      height: 19px;
      padding-top: 4px;
      width: 1px;

      &__home {
        border-left: solid 1px $desktop-menu-text-color-home;
      }

      &__default {
        border-left: solid 1px $desktop-menu-text-color-default;
      }
    }
  }
}

.base-pg-logo {
  width: 100px;
  height: 60px;
  vertical-align: middle;
}

.hamburger {
  &__wrapper {
    background-color: $primary-color-light-site;
    display: none;
    border: solid 2px $mobile-menu-border-color;
    width: 100vw;
    height: 100vh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 14px;
  }

  &__logo {
    padding-top: 9px;
    justify-content: flex-start;

    &-image {
      @extend .base-pg-logo;
    }
  }

  &__close {
    align-items: center;
    display: flex;

    &-image {
      width: 33px;
      height: 33px;
      vertical-align: middle;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-between;
    margin-top: 20%;

    &-item {
      color: $mobile-menu-text-color;
      align-self: center;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    &-line {
      width: 80px;
      border-color: black;
      border-width: 3px;
      border-radius: 7px;
    }
  }
}

.active-page {
  border-bottom-color: #BDAB78 !important;
  border-image-slice: 1;
  border-bottom-style: solid;
  border-bottom: 3px solid;
}
