@import "./ApplyJobForm/ApplyJobForm.module";
@import "./PositionInfo/PositionInfo.module";
@import "./RelatedPositions/RelatedPositions.module";

.position{
    &__wrapper{
        max-width: $large;
        margin: auto;
    }
}




