.careers-modal{
  width:100vw;
  height:100vh;
  background: rgba(54,54,54,0.6);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  animation: smooth-show .5s;

  .card-container{
    @include absolute-center;
    width: 65%;
    background: linear-gradient(to bottom right, #b51a8a, #ef4135);
    padding: 5px;
    position: absolute;

    @media screen and (max-width: $medium1){
      width: 75%;
    }

    @media screen and (max-width: $small0){
      width: 85%;
    }

    @media screen and (max-width: $xsmall + 1px){
      width: 90%;
    }
  }
}

@keyframes smooth-show{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}