.event-card {
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  color: $copy-color-site;
  flex-basis: 100%;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 50px;
  overflow: hidden;

  @media #{$medium-design} {
    flex-basis: 48%;
  }

  &__image {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      margin-left: 170px;
      height: 240px;
      width: auto;

      @media #{$small-design} {
        margin-left: auto;
      }
      @media #{$medium-design} {
        margin-left: 170px;
      }
      @media #{$medium2-design} {
        margin-left: auto;
      }
      @media #{$medium5-design} {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    height: calc(100% - 240px);
    padding: 10px 10px 20px;

    @media #{$medium3-design} {
      padding: 10px 54px 45px;
    }

    &-title {
      font-size: 25px;
      line-height: 1;
      letter-spacing: 1.56px;
      margin-left: 10px;
    }

    &-info {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-date {
      flex: 0 0 60px;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 1;
      margin-top: 30px;
      text-align: center;
      text-align: center;
      text-transform: capitalize;

      &-month {
        color: $title-red-light-color;
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      letter-spacing: 0.96px;
      margin-left: 10px;

      @media #{$medium-design} {
        justify-content: space-between;
      }

      a {
        align-self: flex-end;
        background-color: $color-gimblet;
        border-radius: 28.5px;
        color: $primary-color-site;
        display: inline-block;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 20px;
        padding: 15px 40px;
        text-align: center;
      }
    }
  }
}
