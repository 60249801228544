.LogoGrid {
    &-container {
        margin: 50px 5%;
        @media #{$medium-design}{
            margin: 95px 6% 95px 15%;
        }
        h2 {
            @extend .bold;
            color: $copy-color-site;
            font-size: 24px;
            @media #{$xsmall-design}{
                font-size: 27px;
            }

            @media #{$small-design}{
                font-size: 35px;
            }
            span {
                @extend .regular;
                white-space: nowrap;
            }
            &:before {
                content: "";
                display: block;
                width: 95px;
                height: 1px;
                border-style: solid;
                border-width: 3px;
                color: #0EBAC5;
                margin-bottom: 22px;
            }
            &.near{
                &:before {
                    color: $color-black-medium;
                }
            }
        }
        &--logos {
            display: grid;
            grid-template-columns: repeat( 2, minmax(auto, 190px) );
            justify-content: space-around;

            @media screen and (min-width : 555px){
                grid-template-columns: repeat( auto-fit, minmax(auto, 190px) );
                justify-content: space-between;
            }
            li img {
                width: 100%;
                max-width: 150px;
            }
        }
    }
}