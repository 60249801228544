.making{
    &__wrapper{
        padding-top: 6px;
        @media #{$large-design}{
            margin-left: 140px;
        }
    }
    &__container{
        &-bg{
                @media #{$medium0-design}{
                    background: url('./img/ph-partners-d.png') no-repeat;
                    background-position: initial;
                    position: absolute;
                    right: 0;
                    top: -73px;
                    margin-right: 9%;
                    height: 605px;
                    width: 300px;
                }
                @media #{$medium2-design}{

                    margin-right: 8.7%;
                    width: 350px;
                }
                @media #{$medium3-design}{
                    width: 380px;
                }
                @media #{$medium4-design}{
                    margin-right: 7.5%;
                    width: 440px;
                }
                @media #{$medium5-design}{

                    width: 461px;
                    margin-right: 85px;
                }
        }
        &-title{
            background-image: none;
            background-color: #0EBAC5;
            @media #{$medium0-design}{
                position: relative;
                background-image: none;
                background-color: #0EBAC5;
            }
            .making{
                &__title{
                    text-transform: uppercase;
                    color: $primary-color-site;
                    margin: 0 5% 0 5%;
                    font-size: 32px;
                    line-height: 1.17;
                    padding: 30px 0;
                    @media #{$xsmall-design}{
                        font-size: 36px;
                    }
                    @media #{$small-design}{
                        font-size: 50px;
                    }
                    @media #{$small0-design}{
                        font-size: 60px;
                        padding: 30px 0;
                        margin: 0 9% 0 9%;

                    }
                    @media #{$medium-design}{
                        margin: 0 9% 0 9%;
                    }
                    @media #{$medium0-design}{
                        margin: 0 5% 0 5%;
                        font-size: 35px;
                        max-width: $xsmall;
                    }
                    @media #{$medium1-design}{
                        font-size: 45px;
                        max-width: 430px;
                        padding: 40px 0;
                    }
                    @media #{$medium2-design}{
                        font-size: 48px;
                    }
                    @media #{$medium3-design}{
                        font-size: 50px;
                        max-width: $small;
                    }
                    @media #{$medium4-design}{
                        font-size: 55px;
                    }
                    @media #{$medium5-design}{
                        font-size: 65px;
                        max-width: $small0;
                    }
                    @media #{$large-design}{
                        padding: 49px 0px;
                        font-size: 70px;
                    }
                }
            }
        }
        &-subtitle{
            text-transform: uppercase;
            margin-bottom: 50px;
            @media #{$small-design}{
                margin-bottom: 65px;
            }
            @media #{$small0-design}{
                margin-bottom: 86px;
            }
            @media #{$medium0-design}{
                margin-bottom: 338px;
            }
            @media #{$medium1-design}{
                margin-bottom: 280px;
            }
            @media #{$medium2-design}{
                margin-bottom: 260px;
            }
            @media #{$medium3-design}{
                margin-bottom: 250px;
            }
            @media #{$medium4-design}{
                margin-bottom: 220px;
            }
            @media #{$medium5-design}{
                margin-bottom: 165px;
            }
            @media #{$large-design}{
                margin-bottom: 152px;
            }
            .making{
                &__subtitle{
                    background-image: none;
                    background-color: #0EBAC5;
                    font-weight: 300;
                    margin: 0 5% 0 5%;
                    font-size: 32px;
                    line-height: 1.17;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    padding-top: 18px;
                    @media #{$xsmall-design}{
                        font-size: 36px;
                        padding-top: 16px;
                        max-width: 210px;
                    }
                    @media #{$small-design}{
                        font-size: 50px;
                        max-width: $xsmall;
                    }
                    @media #{$small0-design}{
                        font-size: 60px;
                        padding-top: 10px;
                        margin: 0 9% 0 9%;
                    }
                    @media #{$medium0-design}{
                        margin: 0 5% 0 5%;
                        font-size: 35px;
                        max-width: 230px;
                    }
                    @media #{$medium1-design}{
                        font-size: 45px;
                        max-width: 300px;
                    }
                    @media #{$medium2-design}{
                        padding-top: 8px;
                        font-size: 48px;
                    }
                    @media #{$medium3-design}{
                        font-size: 50px;
                        max-width: $xsmall;
                    }
                    @media #{$medium4-design}{
                        font-size: 55px;
                    }
                    @media #{$medium5-design}{
                        font-size: 65px;
                        padding-top: 5px;
                    }
                    @media #{$large-design}{
                        font-size: 70px;
                        max-width: $small;
                    }
                }
            }
        }
    }
}
