.contact-section {
  width: 90%;
  margin: auto;
  max-width: 1440px;

  .contact_title{
    .black_title{
      background-color: $secondary-color-dark-site;
      letter-spacing: 0px;
      margin-block-end: -10px;

      @media #{$xsmall-design} {
        font-size: 3rem;
      }
      @media #{$medium3-design} {
        font-size: 3.56rem;
      }
      @media #{$medium4-design} {
        font-size: 3rem;
      }
    }
    .subtitle{
      margin-block-start: 0px;
      background-color: $color-cerulean;
      letter-spacing: -0.6px;

      @media #{$xsmall-design} {
        font-size: 3rem;
      }
      @media #{$medium3-design} {
        font-size: 3.56rem;
      }
      @media #{$medium4-design} {
        font-size: 3rem;
      }
    }
  }

  @media #{$medium1-design} {
    width: 95%;
  }
  @media #{$medium3-design} {
    width: 90%;
  }
  @media #{$medium5-design} {
    width: 84%;
  }
  &__container {
    margin-top: 36px;

    @media #{$medium1-design} {
      margin-top: 76px;
      display: flex;
    }

    p {
      margin: 0;
    }

    &--title-container {
      @media #{$medium1-design} {
        width: 45%;
        display: flex;
        flex-direction: column;
        padding-right: 17px;
      }

      @media #{$medium3-design} {
        width: 35%;
      }

      @media #{$medium4-design} {
        width: 40%;
      }

      @media #{$medium5-design} {
        width: 35%;
      }
    }

    &--title {
      font-size: 48px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.9;
      letter-spacing: normal;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;

      @media #{$medium1-design} {
        order: 2;
      }
      //Safari ios similar divices need this
      h2 {
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      //
      span {
        font-size: 55px;
        /* Safari based browsers hides block elements with background clip */
        &:before {
          content: "\A";
        }
      }
    }

    &--copy {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.4;
      letter-spacing: 1.44px;
      color: #383838;
      margin: 57px auto 57px auto;
      @media #{$medium-design} {
        font-size: 16px;
        line-height: 1.4;
      }
      @media #{$medium1-design} {
        order: 1;
        margin: 0;
      }
    }

    &--form {
      width: 100%;

      @media #{$medium1-design} {
        width: 55%;
        padding-left: 17px;
      }

      @media #{$medium3-design} {
        width: 65%;
      }

      @media #{$medium4-design} {
        width: 60%;
      }

      @media #{$medium5-design} {
        width: 65%;
      }
    }
  }
}


