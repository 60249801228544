.career-apply {
  width: 100%;

  &__title {
    display: flex;
    width: 100%;
    height: 181px;
    margin-top: 200px;
    background-color: $color-orange-site;

    @media #{$medium1-design} {
      display: flex;
      padding-top: 54px;
    }
  }

  &__image {
    display: none;

    @media #{$medium1-design} {
      display: block;
      width: 35%;
      margin: -8% auto auto auto;
      padding-right: 3%;
    }

    @media #{$medium2-design} {
      display: block;
      width: 40%;
      margin: -10% auto auto auto;
      padding-right: 3%;
    }

    img {
      float: right;
    }
  }

  &__text {
    width: 90%;
    margin: auto;
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    color: $primary-color-site;

    @media #{$medium1-design} {
      width: 65%;
      margin-top: 0%;
    }

    @media #{$medium2-design} {
      width: 60%;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &-second-line {
      font-weight: normal;
    }
  }

  &__subtitle {
    display: flex;
    width: 90%;
    margin: 14px 0;
    height: auto;
    font-size: 14px;
    @media #{$xsmall-design} {
      font-size: 17px;
    }
    line-height: 1.75;
    color: $copy-color-site;

    @media #{$medium2-design} {
      width: 100%;
    }
  }

  &__container {
    width: 90%;
    margin: auto;

    @media #{$medium1-design} {
      margin-left: 35%;
      width: 65%;
    }

    @media #{$medium2-design} {
      margin-left: 40%;
      width: 60%;
      max-width: 750px;
    }

    @media #{$medium4-design} {
      margin-left: 40%;
      width: 60%;
      max-width: 750px;
    }

    &-row {
      margin: auto;
      width: 100%;

      @media #{$medium1-design} {
        display: flex;
        width: 100%;
      }

      &--input-container {
        width: 100%;
        margin-right: 5%;

        @media #{$medium0-design} {
          margin-right: 1%;

        }
        @media #{$medium3-design} {
          margin-right: 3%;
        }

        @media #{$medium4-design} {
          margin-right: 5%;
        }
      }

      &--input-text {
        width: 99%;
        height: 70px;
        padding: 10px;
        font-size: 20px;
        border-radius: 5px;
        border: solid 1px $select-border-color;
        background-color: $select-background-color;
        color: $copy-color-site;
        margin-bottom: 50px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $input-color-text;
          opacity: 1;
        }

        &:focus {
          border-color: $input-focus-color;
          background-color: $primary-color-site;
        }

        &:not(:placeholder-shown):valid {
          border-color: $input-valid-color;
          background-color: $primary-color-site;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAAAXNSR0IArs4c6QAAAqtJREFUOBGNVE1oU0EQnt28vipN6w9oJSo1VKNCqFIDsTmYph4LNQotHjzWP0ShKRUFkSIeirHxJKgH8SJIBQ1CQQjY1kOj0IsXa6oJFEGQiCK2CAnJujN5u2xI2vgOb36+mdn52wWQ32Q6nEHKiRHg+/r7g2CEzIcFUvoS6fBTNulouNLW2kmEoy1aRPZcBk+rn4yV5SAD9rjF9m09H3hU1AHJRP4YuE7p4Ki8GEiCgNILC4VYzywSSKR7iVqM87gUxkiSv9HQHKODhBiXWcyUFGBSdIodnbmKhw9KYMoE6/EcU1aA295GLANO+RzruKAg4FiXklYKeRjufgYWb6aE3y4/cCCWpRxRSsz3ncTylFOFsuxoaHYv8trw4cK5ptVi5gkINiCY+CabckNW+1w5kmHiXeSOKJd1OxSINNYTcTE2Xmb1jzRNnT6qYVdDNdJQ1bxMeGdrlx6ZrOQ2zRANsIfYHvzCHZfgiGdQtihCMhPMow0PtZ+A4K4z8PPvMmywNunBO5av5Ah7vwCITlTsbjsMXe0DMP35FuHq57b329Se9QpSS0HF4BrJ3mdVBEVxO3FzUKaICkBa6Ws5rtIxsWoe58/HYqE3L029DrjeFE2HerwqAzGGO7NSWFpsnFG9UKaOZd227yCnBXS6bcKN+LbmHXDafx+iByYcU9GJsSzcZpldI3+N+7f3w3HvCLi4BblfaZheMkYtY1nO1fBpD8mc7Z6CjU2bIZWNw+KPFNiuFujfdxO8W4JQKhchlbsLH/OvTRfiMdaabwQuWp/3CnBWWdb8ag6Sn67Dn8L3mkCGYoim/D9X0HCqy9IuyhXSa4NP3r33cxNrPQB1o0glrsxIMHwNHwe00QFNh8oqZaLydY3KcQXwliJOPQJYEAKS8p4l8VE3/ZD/B91++md5sP+sAAAAAElFTkSuQmCC') no-repeat 95% 50%;
        }

        &:not(:placeholder-shown):invalid {
          border-color: $input-invalid-color;
          background-color: $primary-color-site;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAAAXNSR0IArs4c6QAAAqNJREFUOBGNVE1oU0EQnn2vXkxKXj16UEvjQRCsYHOVRvAiiBVE9KSIFS+iQhQkYkDowUBFL0JE9FJFBVsEL4IRr6mggiBKYtWD1yTk5WTTdb55mc1LE6UD783uzDezs/OzREyNbOYruCwsU316yhoIsAAXYtiCSFa/V61R1SCOwZ5geTH64AkF5UrkAH9WHKtPZ1p2dnYT9s4PNkLGHPWwMKmARh8+jYTWvhBhcv4ehRfPiVJAjQOZW3bN5iIY0djbZSMh2ULBa7x71VFFnBvPFIM3lSsG0fB9n0GJMJszB8k2GxK2bYcUXjgrdhxlpkVkk+oFgfnbx52Byr1g5+QW3STv3qfW6eO4M1/HUGJuPvJmqCYxYlfPZmaIr6dG4IytBeXltKxVgYw1qx8fWUuHGfCb5XmO+bnqxeP6dKgSPNh/yDeFwpoZdmQciDXy6GlcuAgqCUJZ4hVFCvuKKEDfJ3/bjr70GGO+SQ3FDf/Cy+cF1Pn1Q5Kucr7gVgfEcanF1+KJOh0XBsCchZcecoXN5qs33HGtMycI5VNKpSdPDe1TBYC7plBhIztV5VgmdC+cu3OsXFnE2pVQAcirIVscMFJAlyNkSyanjlTtHP6vigr+F9drQG+invnwJR5RYu42mWSS2tdzfWkXA8524maRbBhS+9oldwYiTqX37sLALPDAnHQanMJGmEt/fII6KzWpK/TrZRisOHFDPB4YrD4AHN8pSZNA3vm5IsO/3lHPxoToB4zGALkouS3hCF2HCUaU0A0j+BphRZ4/eSMUhIEY2b1HrqtvBnR6CDp29fMn936oHfN81IhDpj4G2tiy24syK+glGXB+2jZm3UNJy/DjoP3o+rAH4bcven6OsAzfPkwp9N18v+flEs/ZkimV/kAep7/+3TpdFY2E9wAAAABJRU5ErkJggg==') no-repeat 95% 50%;
        }

        @media #{$medium1-design} {
          width: 100%;
          height: 45px;
          font-size: 16px;
        }
      }

      ::placeholder {
        font-size: 20px;

        @media #{$medium1-design} {
          font-size: 16px;
        }
      }

      .country-selector {
        display: block;
        background: url("./img/black-triangle.png") no-repeat;
        background-size: auto;
        background-position: 93%;
        appearance: none;
        font-family: 'GothanBook';
        background-color: $select-background-color;
        color: $copy-color-site;
      }

      .PhoneInputCountrySelect {
        display: block;
        margin-right: 0 !important;
      }

      .PhoneInputCountrySelectArrow {
        background: url("./img/black-triangle.png") no-repeat;
        background-size: auto;
        background-position: 93%;
        width: 0.9em !important;
        height: 1.3em !important;
        opacity: 1;
        border: 0;
        transform: rotate(0deg);
        margin-left: 0;

        @media #{$medium1-design} {
          margin-left: 0em;
        }

        @media #{$medium3-design} {
          margin-left: 0.1em;
        }

        @media #{$medium4-design} {
          margin-left: 0.2em;
        }

        @media #{$medium5-design} {
          margin-left: 0.35em;
        }
      }

      .input-number {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        flex-basis: 61%;
        -moz-appearance: textfield;
        font-family: 'GothanBook';

        @media screen and (min-width: 400px) {
          flex-basis: 66%;
        }

        @media #{$small-design} {
          flex-basis: 71%;
        }

        @media #{$small0-design} {
          flex-basis: 76%;
        }

        @media #{$medium-design} {
          flex-basis: 78%;
        }

        @media #{$medium1-design} {
          flex-basis: 56%;
        }

        @media #{$medium4-design} {
          flex-basis: 58%;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .input-flag {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
        flex-basis: 38%;
        padding-right: 0%;

        @media #{$xsmall-design} {
          padding-right: 4%;
        }

        @media screen and (min-width: 400px) {
          flex-basis: 33%;
        }

        @media #{$small-design} {
          flex-basis: 28%;
        }

        @media #{$small0-design} {
          flex-basis: 23%;
        }

        @media #{$medium-design} {
          flex-basis: 21%;
        }

        @media #{$medium1-design} {
          flex-basis: 43%;
        }

        @media #{$medium4-design} {
          flex-basis: 41%;
        }
      }

      .input-separator {
        height: 70px;
        border: solid 1px $select-border-color;
        @media #{$medium1-design} {
          height: 45px;
        }
      }

      .PhoneInputInput {
        border: 0;
        background-color: $select-background-color;
        text-align: center;
        width: 90%;
        margin-left: -8px;

        &::placeholder{
          opacity: 1;
          color: $copy-color-site;
        }

        @media #{$medium0-design} {
          margin-left: -5px;
        }
      }

      &-mobile {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media #{$medium0-design} {
          margin-right: 1%;
        }

        @media #{$medium3-design} {
          margin-right: 3%;
        }

        @media #{$medium4-design} {
          margin-right: 5%;
        }
      }

      &-country {
        width: 100%;

        @media #{$medium0-design} {
          margin-right: 1%;
        }

        @media #{$medium3-design} {
          margin-right: 3%;
        }

        @media #{$medium4-design} {
          margin-right: 5%;
        }
      }
    }

    .input-check {
      margin-bottom: 60px;

      &__title {
        height: 25px;
        font-size: 14px;
        color: $copy-color-site;
        margin-bottom: 60px;
        font-weight: bold;

        @media #{$small-design} {
          font-size: 16px;
        }
      }

      &__item {
        position: initial;
        opacity: 1;
        margin-right: 10px;
      }

      &__link{
        font-size: 14px;
        color: $secondary-color-dark-site;

        @media #{$small-design} {
          font-size: 16px;
        }

      }
    }

    span {
      &.errorMsg {
        color: #cc0000;
        position: absolute;
        display: block;
        margin-top: -50px;
      }

      &.mobile-msg {
        margin-top: 70px;
        @media #{$medium0-design} {
          margin-top: 45px;
        }
      }
    }
  }

  &__button-container {

    width: 100%;
    &.with-margin {
      margin-top: 60px;
    }
    @media #{$medium1-design} {
      display: flex;
      justify-content: flex-end;
    }

    .cta-secondary{
      a{
        color: #552988;
      }
    }
    a.cta {
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      line-height: 36px;
      @media #{$medium1-design} {
        line-height: 16px;
      }
    }
    button, a {
      width: 100%;
      height: 70px;
      margin-bottom: 60px;
      padding: 0;

      @media #{$medium1-design} {
        width: 263px;
        height: 50px;
        font-size: 16px;
        margin-right: 1%;
      }

      @media #{$medium3-design} {
        margin-right: 3%;
      }

      @media #{$medium4-design} {
        margin-right: 5%;
      }
    }
  }
}
