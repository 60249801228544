.img-slide-container {
    position: relative;
}

.carousel{

    &-video{
        position: absolute;
        bottom: 9%;
        color: $primary-color-site;
        right: 5%;
        text-align: right;
        width: 100%;

    h2 {
        font-size: 26px;
        line-height: 35px;
        font-family: 'Gotham-Black';
        width: 90%;
        float: right;
        position: relative;
        margin-bottom: 6px;

        &:after {
            height: 1px;
            background: $primary-color-site;
            content: "";
            display: block;
            right: 0;
            position: absolute;
            width: 100%;
            bottom: -11px;
        }
    }

    p {
        font-size: 15px;
        line-height: 21px;
        font-family: 'GothanBook';
        width: 80%;
        float: right;
    }

    @media #{$medium-design} {
        bottom: 10%;

        h2 {
            font-size: 40px;
            line-height: 50px;
            width: 70%;

            &:after {
                bottom: -18px;
            }
        }

        p {
            font-size: 22px;
            line-height: 34px;
            width: 70%;
        }
    }

    @media #{$medium2-design} {
        width: 56%;

        h2 {
            font-size: 60px;
            line-height: 70px;
            width: 95%;
        }

        p {
            font-size: 30px;
            line-height: 42px;
        }
    }
  }

  &-img {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 16%;
    color: $primary-color-site;
    right: 5%;
    text-align: right;
    max-width: 330px;

    @media #{$small-design} {
        max-width: 430px;
    }

    @media #{$small0-design} {
        max-width: 550px;
    }

    @media #{$medium-design} {
        bottom: 13%;
        max-width: 450px;
    }

    @media #{$medium0-design} {
        max-width: 350px;
    }

    @media #{$medium1-design} {
        max-width: 450px;
    }

    @media #{$medium4-design} {
        max-width: 1024px;
        bottom: 25%;
    }

    h2 {
        margin: 0;
        font-size: 30px;
        line-height: 1;
        letter-spacing: -0.6px;
        width: 70%;
        float: right;
        position: relative;
        padding-bottom: 16px;

        @media #{$xsmall-design} {
            font-size: 36px;
            width: 77%;
        }

        @media #{$small-design} {
            font-size: 42px;
        }

        @media #{$small0-design} {
            font-size: 55px;
        }

        @media #{$medium-design} {
            font-size: 32px;
        }

        @media #{$medium0-design} {
            font-size: 35px;
            padding-bottom: 0;
            width: 80%;
        }

        @media #{$medium1-design} {
            font-size: 40px;
            width: 75%;
        }

        @media #{$medium4-design} {
            font-size: 60px;
        }
    }

    h3 {
        margin: 0;
        font-family: 'Gotham-Black';
        font-size: 22px;
        line-height: 1.11;
        width: 90%;
        float: right;

        @media #{$xsmall-design} {
            font-size: 32px;
        }

        @media #{$small-design} {
            font-size: 42px;
        }

        @media #{$small0-design} {
            font-size: 55px;
        }

        @media #{$medium-design} {
            font-size: 34px;
        }

        @media #{$medium0-design} {
            font-size: 45px;
            line-height: 1.25;
        }

        @media #{$medium1-design} {
            font-size: 60px;
        }

        @media #{$medium4-design} {
            font-size: 80px;
        }
    }

    p {
        display: inline-block;
        font-family: 'GothanBook';
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 1;
        margin: 14px 0 17px;
        max-width: 200px;

        @media #{$xsmall-design} {
            font-size: 22px;
            max-width: 245px;
        }
        @media #{$medium-design} {
            font-size: 16px;
            margin: 16px 0 22px;
            max-width: 330px;
        }
        @media #{$medium0-design} {
            font-size: 24px;
            max-width: 485px;
        }
        @media #{$medium2-design} {
            font-size: 30px;
            margin: 19px 0 30px;
            max-width: 600px;
        }
    }

    .round-cta {
        @media #{$small-and-down} {
            padding: 12px 16px;
        }
    }

    &__special {
        bottom: 15%;
        @media #{$medium1-design} {
            bottom: 12%;
        }

        h2 {
            font-size: 26px;
            line-height: 1.45;

            @media #{$xsmall-design} {
                font-size: 32px;
            }

            @media #{$small-design} {
                font-size: 44px;
            }

            @media #{$small0-design} {
                font-size: 55px;
            }

            @media #{$medium-design} {
                font-size: 28px;
            }

            @media #{$medium0-design} {
                font-size: 45px;
                line-height: 1.67;
            }

            @media #{$medium1-design} {
                font-size: 58px;
            }
        }
        h3 {
            font-size: 44px;
            line-height: 0.77;

            @media #{$xsmall-design} {
                font-size: 54px;
            }

            @media #{$small-design} {
                font-size: 74px;
            }

            @media #{$small0-design} {
                font-size: 91px;
            }

            @media #{$medium-design} {
                font-size: 47px;
            }

            @media #{$medium0-design} {
                font-size: 76px;
                line-height: 0.71;
            }

            @media #{$medium1-design} {
                font-size: 99px;
            }
        }
    }
    &__line-divider--mobile {
        border-bottom: 1px solid $color-silver;
        width: 75%;

        @media #{$xsmall-design} {
            width: 84%;
        }
        @media #{$medium0-design} {
            display: none;
        }
    }
    &.text-position {
        &-left {
            align-items: flex-start;
            left: 6%;
            right: auto;

            h2,
            h3,
            p {
                text-align: left;
            }
        }

        &-center {
            align-items: center;
            max-width: none;
            right: auto;
            width: 100%;
        }
    }

    &__digital-uplevel-summit {
        bottom: 50%;

        @media #{$small-design} {
            bottom: 55%;
        }
        @media #{$medium-design} {
            bottom: 40%;
        }
        @media #{$medium0-design} {
            bottom: 43%;
        }

        .round-cta {
            left: 30%;
            position: relative;

            @media #{$medium-design} {
                left: -12.5%;
            }
            @media #{$medium0-design} {
                left: -16.5%;
            }
            @media #{$medium5-design} {
                left: -18%;
            }
        }
    }

    &__uplevel-talks.text-position-left {
        bottom: 35%;
        left: 9%;
        width: 100%;

        @media #{$xsmall-design} {
            bottom: 40%;
        }
        @media #{$small-design} {
            left: 11.5%;
            bottom: 43%;
        }
        @media #{$medium-design} {
            bottom: 20%;
        }
        @media #{$medium0-design} {
            bottom: 25%;
        }
    }
  }
}