.about{
    &__magic{
        &-container{
            padding: 38px 5% 35px 5%;
            @media #{$small-design}{
                padding: 78px 8% 75px 8%;
            }
            @media #{$small0-design}{
                padding: 98px 9% 95px 9%;
            }
            @media #{$medium-design}{
                padding: 98px 10% 95px 10%;
            }
            @media #{$medium0-design}{
                display: flex;
                flex-direction: row-reverse;
                padding: 74px 3% 95px 5%;
            }
            @media #{$medium1-design}{
                padding: 74px 3% 95px 4%;
            }
            @media #{$medium4-design}{
                padding: 74px 3% 95px 3%;
            }
            @media #{$medium5-design}{
                padding: 74px 3% 95px 3.5%;
            }
            @media #{$large-design}{
                padding:  74px 99px 115px 180px;
            }
        }   
        &-title {
            .magic {
               &__title {
                    margin: 0;
                    text-transform: uppercase;

                    @media #{$medium0-design}{
                        white-space: nowrap;
                    }

                    &-normal {
                        background-color: $color-cerulean;
                        font-size: 35px;
                        line-height: 1;
                        letter-spacing: normal;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;

                        @media #{$xsmall-design} {
                            font-size: 40px;
                        }

                        @media #{$small0-design}{
                            font-size: 45px;
                        }

                        @media #{$medium0-design}{
                            font-size: 36px;
                        }

                        @media #{$medium2-design}{
                            font-size: 47px;
                        }

                        @media #{$medium3-design}{
                            font-size: 50px;
                        }

                        @media #{$medium4-design}{
                            font-size: 60px;
                        }
                    }
                    &-bold{
                        background-color: $secondary-color-dark-site;
                        font-size: 42px;
                        line-height: 0.9;
                        letter-spacing: normal;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        @media #{$xsmall-design}{
                            font-size: 50px;
                        }
                        @media #{$small0-design}{
                            font-size: 54px;
                        }
                        @media #{$medium0-design}{
                            font-size: 43px;
                        }
                        @media #{$medium2-design}{
                            font-size: 52px;
                        }
                        @media #{$medium3-design}{
                            font-size: 55px;
                        }
                        @media #{$medium4-design}{
                            font-size: 75px;
                        }
                    }
                }
            }
        }
        &-text{
            margin-top: 30px;
            font-size: 20px;
            line-height: 1.88;
            letter-spacing: 1.24px;
            color: $copy-color-site;

            @media #{$medium0-design} {
                margin-top: 0;
                margin-right: 40px;
            }

            @media #{$medium3-design} {
                line-height: 2.5;
                letter-spacing: 1.16px;
            }

            @media #{$medium4-design} {
                margin-right: 60px;
            }
        }
    }
}