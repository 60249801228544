.footer {
    background-color: $secondary-color-dark-site;
    color: $primary-color-site;
    padding: 0 5%;
    margin: 0 auto;

    &__wrapper {
        max-width: $large;
        padding: 18px 0 20px 0;
        text-align: center;
        margin: 0 auto;

        @media #{$medium-design} {
          padding-bottom: 12px;
        }

        .country {
            &__wrapper{
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 22px 0 22px 0;

                @media #{$small-design} {
                    margin: 55px 0 22px 0;
                }

                @media #{$medium1-design} {
                    justify-content: space-between;
                    flex-direction: row;
                }

                .social {
                    &__icons {
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media #{$medium1-design} {
                            margin-top: 0;
                            justify-content: flex-end;
                        }

                        &-item {
                            margin: 0 29px;

                            @media #{$medium0-design} {
                              margin: 0 78px;
                            }

                            @media #{$medium1-design} {
                              margin: 0 13px;
                            }
                        }
                    }
                  }
                }
            &__content{
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media #{$small0-design} {
                    flex-direction: row;
                    justify-content: space-around;
                }

                @media #{$medium-design} {
                    justify-content: flex-start;
                }

                .country{
                    &-name{
                        font-size: 22px;
                        text-align: center;
                        margin: 0 0 3px 0;

                        @media #{$small0-design} {
                            text-align: left;
                            font-size: 30px;
                        }

                        @media #{$medium-design} {
                            font-size: 20px;
                        }
                    }
                    &-links{
                        color: $primary-color-site;
                        font-size: 16px;
                        display: block;
                        text-align: center;

                        @media #{$small0-design} {
                            text-align: left;
                        }

                        @media #{$medium5-design} {
                            display: inline-block;
                        }
                    }
                    &-phone{

                        @media #{$medium5-design} {
                            margin-right: 15px;
                        }

                        @media #{$large-design} {
                            margin-right: 25px;
                        }
                    }
                }

                &-costarica {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 25px;
                  text-align: left;
                }

                &-colombia {
                  margin-bottom: 35px;

                  @media #{$medium1-design} {
                    margin-bottom: 0;
                  }
                }
            }
        }
    }
    &__logowrapper{
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$medium3-design} {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__logo{
      margin-bottom: 5px;



      a {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;

        img {
          margin-top: 17px;

          &.global-delivery {
            height: 50px;
            width: 51px;
          }

          &.publicis-media {
            width: 60px;
            margin-left: 26px;
          }

          &.prodigious {
            width: 129px;
            margin-left: 0;
          }

          &.digitas {
            width: 52px;
            margin-left: 25px;
          }
        }
      }

      @media #{$small-design} {
        margin-bottom: 0px;

        a {
          @media #{$small-design} {
            flex-direction: row;

            img.prodigious {
              margin-left: 27px;
            }
          }
        }
      }

      @media #{$medium-design} {
        justify-content: flex-start;
      }
    }

    &__navigation{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 13px;

        @media #{$medium3-design} {
            justify-content: flex-end;
            padding-top: 0;
        }

        &-item {
            margin: 0 8px;
            font-size: 16px;
            color: $primary-color-site;

            @media #{$small-design} {
                margin: 0 23px;
                font-size: 20px;
            }

            @media #{$medium-design} {
                font-size: 15px;
                margin: 0 25px;
            }

            @media #{$medium0-design} {
                margin: 0 50px;
            }

            @media #{$medium3-design} {
                margin: 0 30px;
            }
        }

        &-separator{
            width: 1px;
            height: 19px;
            border: $primary-color-site;
            background: linear-gradient(to bottom, #ffffff, #979797);
        }
    }
    &__line{
        height: 1px;
        border: $primary-color-site;
        background: linear-gradient(to bottom, #ffffff, #979797);
    }
    &__copyright{
        margin: 18px 0 0 0;
        font-size: 12px;
    }
}
