
$num-circles: 5;
$anim-dur: 1000ms;
$anim-delay: $anim-dur / $num-circles;

.icim-card{
    width: 100%;
    height: 31.25rem;
    background: black;
    padding: 0 10.5rem;

    @media screen and (max-width: $medium5) {
        height: 29.25rem;
        padding: 0 8.5rem; 
    }

    @media screen and (max-width: $medium4 +1px){
        padding: 0 7.5rem;
    }

    @media screen and (max-width: $medium3){
        padding: 0 4.75rem;
    }

    @media screen and (max-width: $small){
        padding: 0 3.75rem;
    }
    
    &__body{
        width: 100%;
    }

    &__image{
        transform: translate(15px, 30px);
        width: 200px;
        height: 200px;

        @media screen and (max-width: $medium5) {
            transform: translate(15px, 36px);
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width: $medium4 + 1px){
            width: 120px;
            height: 120px;
        }
        
        @media screen and (max-width: $medium3){
            display: block;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            transform: translate(0);
        }
    }
    
    &__text{
        color: white;
        font-family: Helvetica;
        font-size: 1.875rem;
        line-height: 3.75rem;
        letter-spacing: 2.17px;

        @media screen and (max-width: $medium3){
            font-size: 1.56rem;
            line-height: 3rem;
            text-align: center;
            padding-top: 55px;
        }

        @media screen and (max-width: $small){
            padding-top: 30px;
        }

        p{
            margin: 0;
        }
    }
    
    &__loading{
        width: 50%;
        height: 25px;
        margin:89px auto 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $medium0){
            margin:49px auto 0 auto;
        }
    
        @media screen and (max-width: $xsmall + 1px){
            margin:20px auto 0 auto;
        }

        .circle{
            width: 20px;
            height: 20px;
            border-radius:50%;
            animation: background-anim $anim-dur infinite alternate;

            @media screen and (max-width: $medium3){
                width: 17px;
                height: 17px;
            }

            @media screen and (max-width: $xsmall + 1px){
                width: 15px;
                height: 15px;
            }

            @for $i from 0 through $num-circles - 1{
                &--#{$i}{
                    animation-delay: $anim-delay * $i ;
                }
            }
        }
    }
}

@keyframes background-anim{
    0%{
        background-color: #f7941e;
    }
    50%{
        background-color: #ef4135;
    }
    100%{
        background-color: #b51a8a;
    }
}