@import "./ImgSlide/ImgSlide.module";

.home-carousel {
    width: 100%;

    .slick-list {
        @media #{$medium3-design} {
            max-height: 680px;
        }
    }

    .slick-slide {
        position: relative;

        img {
            max-width: 100%;
            max-height: none;
            width: 100%;

            @media #{$medium-design} {
                max-width: 100%;
                max-height: none;
                width: 100%;
            }
        }
    }

    .slick-dots {
        bottom: 5%;
        right: 5%;
        width: auto;

        li {
            margin: 0;

            &.slick-active {
                button:before {
                    color: $color-crusta;
                    opacity: 1;
                    font-size: 13px;
                }
            }
            button:before {
                color: $dots-gray-color;
                opacity: 1;
                font-size: 13px;
            }
        }
    }

    .slick-next {
        right: 2%;
        width: 22px;
        height: 22px;
    }

    .slick-prev {
        left: 2%;
        z-index: 1;
        width: 22px;
        height: 22px;
    }

    .slick-prev:before,
    .slick-next:before {
        opacity: 0;
    }

    @media #{$medium-design} {
        .slick-dots {
            bottom: 4%;
        }

        .slick-next {
            right: 0
        }

        .slick-prev {
            left: 0
        }

        .slick-prev,
        .slick-next {
            width: 8%;
            height: 60%;

            &:before {
                font-size: 40px;
                opacity: 0;
            }

            &:hover,
            &:focus {
                &:before {
                    opacity: .5;
                }
            }
        }
    }
}

