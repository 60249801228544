.hero {
  &__wrapper.masthead {
    @media #{$medium-design} {
      padding-top: 48px;
    }
  }
  &__masthead {
    @media #{$medium-design} {
      position: relative;
      height: 445px;
    }
    @media #{$medium0-design} {
      height: 500px;
    }
    @media #{$medium1-design} {
      height: 560px;
    }
    @media #{$medium2-design} {
      height: 600px;
    }
    @media #{$medium4-design} {
      height: 660px;
    }
    &-responsive {
      position: relative;

      @media #{$medium-design} {
        position: relative;
        height: 433px;
      }
      @media #{$medium0-design} {
        height: 440px;
      }
      @media #{$medium1-design} {
        height: 480px;
      }
      @media #{$medium4-design} {
        height: 632px;
      }
      @media #{$medium4-design} {
        height: 645px;
      }
    }
    &-bg:not(.hero__masthead-bg-responsive) {
      height: 395px;
      width: 100%;
      background-position: 50% 0%;
      @media #{$medium-design} {
        background-position: 50% 0%;
        width: 50%;
        position: absolute;
        left: 0;
        z-index: 1;
      }
      @media #{$medium0-design} {
        height: 450px;
      }
      @media #{$medium1-design} {
        background-position: 50% 70%;
        height: 510px;
      }
      @media #{$medium2-design} {
        height: 560px;
      }
      @media #{$medium4-design} {
        height: 610px;
      }
      @media #{$medium5-design} {
        background-position: initial;
      }
      @media #{$large-design} {
        width: 700px;
      }
    }
    &-bg-responsive {
      position: relative;
      width: 100%;
      z-index: 1;
      @media #{$medium-design} {
        height: auto;
        width: 420px;
      }
      @media #{$medium0-design} {
        height: auto;
        width: 460px;
      }
      @media #{$medium1-design} {
        width: 507px;
      }
      @media #{$medium4-design} {
        width: 675px;
      }
      @media #{$medium5-design} {
        width: 700px;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
    &-container {
      color: $primary-color-site;
      padding: 28px 8% 28px 8%;
      @media #{$xsmall-design} {
        padding: 28px 8% 82px 8%;
      }
      @media #{$medium-design} {
        padding: 115px 8% 2px 12%;
        width: 58%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      @media #{$medium0-design} {
        height: 450px;
      }
      @media #{$medium1-design} {
        height: 510px;
      }
      @media #{$medium2-design} {
        height: 560px;
      }
      @media #{$medium4-design} {
        height: 602px;
      }
      @media #{$medium5-design} {
        padding: 115px 2% 2px 11%;
      }
      @media #{$large-design} {
        padding: 115px 2% 2px 8%;
        width: 769px;
      }
      &-responsive {
        overflow: hidden;

        @media #{$small-and-down} {
          top: -7px;
          position: relative;
          padding-bottom: 50px;
          padding-top: 50px;
        }
        @media #{$medium-design} {
          height: 350px;
          padding-left: 156px;
          padding-top: 52px;
        }
        @media #{$medium0-design} {
          height: 410px;
        }
        @media #{$medium1-design} {
          height: 450px;
        }
        @media #{$medium3-design} {
          height: 450px;
          padding: 40px 47px 0 134px;
        }
        @media #{$medium4-design} {
          height: 602px;
          padding-left: 318px;
          padding-top: 100px;
          width: 770px;
        }
        @media #{$medium5-design} {
          padding-left: 200px;
        }
        @media #{$large-design} {
          padding-left: 110px;
          padding-top: 95px;
        }

        &::before {
          @extend .dotted-bg__purple;
          background-size: 27% 27%;
          content: "";
          height: 100%;
          left: 10px;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;

          @media #{$xsmall-design} {
            left: 0;
            background-size: 29% 27%;
          }
          @media #{$medium-design} {
            background-size: 14% 17%;
          }
          @media #{$large-design} {
            background-size: 14% 16%;
            top: 27px;
            left: -80px;
          }
        }
      }
    }
    .masthead {
      &__title {
        font-size: 48px;
        line-height: 1;
        position: relative;
        margin: 0;
        max-width: $xsmall;
        z-index: 2;
        @media #{$small0-design} {
          max-width: $small;
        }
        @media #{$xsmall-design} {
          font-size: 56px;
        }
        @media #{$small-design} {
          font-size: 66px;
        }
        @media #{$small0-design} {
          font-size: 80px;
        }
        @media #{$medium-design} {
          font-size: 42px;
          line-height: 1;
        }
        @media #{$medium0-design} {
          font-size: 42px;
        }
        @media #{$medium1-design} {
          font-size: 56px;
        }
        @media #{$medium2-design} {
          font-size: 66px;
        }
        @media #{$medium3-design} {
          font-size: 70px;
        }
        @media #{$medium4-design} {
          font-size: 65px;
        }
        @media #{$medium5-design} {
          font-size: 85px;
        }
        @media #{$large-design} {
          max-width: $small0;
          font-size: 100px;
          line-height: 0.85;
        }
        &-bold {
          font-size: 45px;
          -webkit-text-stroke: 2px #ffffff;
          color: rgba(0, 0, 0, 0);
          line-height: 1;
          @media #{$small0-design} {
            max-width: $small;
          }
          @media #{$xsmall-design} {
            font-size: 50px;
          }
          @media #{$small-design} {
            font-size: 66px;
          }
          @media #{$small0-design} {
            font-size: 80px;
          }
          @media #{$medium-design} {
            font-size: 42px;
            line-height: 1;
          }
          @media #{$medium0-design} {
            font-size: 42px;
          }
          @media #{$medium1-design} {
            font-size: 56px;
          }
          @media #{$medium2-design} {
            font-size: 66px;
            line-height: 0.9;
          }
          @media #{$medium3-design} {
            font-size: 70px;
          }
          @media #{$medium4-design} {
            font-size: 65px;
          }
          @media #{$medium5-design} {
            font-size: 85px;
          }
          @media #{$large-design} {
            font-size: 100px;
          }
        }
      }
      &__subtitle {
        position: relative;
        font-size: 18px;
        line-height: 1;
        margin: 16px 0 20px;
        z-index: 2;

        @media #{$xsmall-design} {
          font-size: 20px;
        }
        @media #{$medium-design} {
          font-size: 16px;
        }
        @media #{$medium0-design} {
          font-size: 20px;
          margin-bottom: 28px;
        }
        @media #{$medium1-design} {
          font-size: 24px;
        }
        @media #{$medium2-design} {
          font-size: 26px;
        }
        @media #{$medium3-design} {
          font-size: 28px;
        }
        @media #{$medium4-design} {
          font-size: 30px;
        }
        @media #{$medium5-design} {
          font-size: 32px;
        }
      }
      &__cta {
        @include round-cta-call($secondary-color-dark-site, $color-gimblet);
        align-items: center;
        display: flex;
        font-size: 16px;
        height: 50px;
        justify-content: center;
        padding: 0;
        position: relative;
        z-index: 2;
        width: 130px;
      }
    }
  }
}
