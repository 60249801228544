.event-info {
  background-color: $primary-color-site;
  padding: 28px 0 35px 0;
  margin: 0 5%;

  h2 {
    font-family: Gotham-Black;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    background: linear-gradient(98deg, #b51a8a, #ef4135 40%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .event-date {
    font-family: GothanBook;
    font-size: 6.6875vw; // ~38px in sketch
    color: $copy-color-site;

    @media #{$medium-design} {
      font-size: 28px;
    }
  }

  .event-title {
    font-size: 9.34vw; // ~35px in sketch
    color: $mid-gray-color-site;
    margin: 6.25vw 0;

    @media #{$medium-design} {
      margin: 40px 0;
      font-size: 58px;
    }
  }

  .event-content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    > div {
      flex-basis: 48%;

      img {
        max-width: 100%;
      }
    }
  }
}
