.position-details {
  background-color: $primary-color-site;
  padding: 28px 0 35px 0;
  margin-bottom: 0rem;
  h2 {
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    background: linear-gradient(90deg, #0EBAC5, #0EBAC5 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0rem;
  }
  .job-date {
    font-family: GothanBook;
    font-size: 6.6875vw; // ~38px in sketch
    color: $copy-color-site;
    @media #{$medium-design} {
      font-size: 28px;
    }
  }
  .job-title {
    font-size: 9.34vw; // ~35px in sketch
    color: $mid-gray-color-site;
    margin: 6.25vw 0;
    @media #{$medium-design} {
      margin: 40px 0;
      font-size: 58px;
    }
  }
  h3.jobSubtitle,
  h4.jobSubtitle {
    background: $color-cerulean;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "GothanBook";
  }
  h3.jobSubtitle {
    font-size: 28px;
    margin: 62px 0 27px;
    text-transform: uppercase;
    @media #{$xsmall-design} {
      font-size: 35px;
    }
    @media #{$medium3-design} {
      margin-top: 4px;
      text-align: right;
      flex-basis: 37%;
      min-width: 37%;
    }
    @media #{$medium4-design} {
      font-size: 20px;
    }
  }
  .jobContent {
    @media #{$small0-design} {
      margin-left: 72px;
    }
    @media #{$medium3-design} {
      margin-left: 15px;
    }
  }
  .jobSection {
    @media #{$medium3-design} {
      display: flex;
      flex-direction: row;
    }
  }

  .item_jobs {
    margin: 0 5%;
    .data-jobs {
      strong {
        @extend .bold;
      }
      p,
      span {
        color: $copy-color-site;
        margin: 0 0 0.5em 0;
      }
      p,
      ul li {
        color: $copy-color-site;
        font-family: GothanBook;
        font-size: 16px;
        line-height: 1.89;
      }
      ul li {
        list-style-type: disc;
      }
      ul {
        list-style-type: disc;
        padding-left: 3rem;
        margin-top: 0;
      }
      .col ul:first-child {
        margin-top: 0;
      }
      .buttonContainer {
        align-items: center;
        flex-direction: column;
        @media #{$medium3-design} {
          flex-direction: row;
          align-items: inherit;
        }
        .buttonleft {
          @media #{$medium3-design} {
            margin-right: 15px;
          }
          @media #{$large-design} {
            margin-right: 0;
          }
        }
      }
    }
  }
  .ShareBar {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    @media #{$small-design} {
      flex-direction: row;
    }
    .ShareBar-title {
      font-family: GothanBook;
      font-size: 20px;
      line-height: 1.89;
      letter-spacing: 1.3px;
      color: $copy-color-site;
      padding-bottom: 15px;
      padding-right: 15px;
      white-space: nowrap;
    }
    .ShareBar-buttons {
      display: flex;
    }
    &__FacebookShare-button,
    &__WhatsappShare-button,
    &__LinkedinShare-button,
    &__CopyToClipboard-button {
      width: 32px;
      height: 32px;
      background: url("./img/social-share-bar.png");
      margin-right: 32px;
      @media #{$medium3-design} {
        margin-right: 26px;
      }
    }
    &__FacebookShare-button {
      background-position: -166px -10px;
      &:hover {
        background-position: -114px -10px;
      }
    }
    &__WhatsappShare-button {
      background-position: -270px -10px;
      &:hover {
        background-position: -218px -10px;
      }
    }
    &__LinkedinShare-button {
      background-position: -375px -10px;
      &:hover {
        background-position: -322px -10px;
      }
    }
    &__CopyToClipboard-button {
      background-position: -62px -10px;
      &:hover {
        background-position: -10px -10px;
      }
    }
  }
  .jobSection {
    @media #{$medium3-design} {
      margin-top: 47px;
    }
  }
}
