.events {
  &__hero.masthead {
    @media #{$medium-design} {
      padding-top: 0;
    }
    .hero {
      &__masthead {
        &-container {
          background-color: $color-royal-purple;
          &-responsive {
            @media #{$medium1-design} {
              padding-left: 155px;
            }
            @media #{$medium2-design} {
              padding-left: 135px;
            }
            @media #{$medium3-design} {
              padding-left: 125px;
            }
            @media #{$medium4-design} {
              padding-left: 338px;
            }
            @media #{$medium5-design} {
              padding-left: 220px;
            }
            @media #{$large-design} {
              padding-left: 85px;
            }
          }
        }
      }
    }
    .masthead__title-bold {
      display: inline-block;
    }
  }
}
