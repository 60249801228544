.publicis{
    &__container {
      position: relative;
      padding-bottom: 50px;

      .common-height {
        height: 485px;
      }

      .publicis__lines__bg {

        img {
          @extend .common-height;
        }

        &.desktop {
          position: absolute;
          z-index: -1;
          display: none;
        }

        &.mobile {
          display: block;
          img {
            position: absolute;
            z-index: -1;
          }
        }

        img {
          width: 100%;
        }

        @media #{$medium0-design} {
          &.desktop {
            display: block;
          }
          &.mobile {
            display: none;
          }
        }

      }

      &-bg {
        img {
          background-position: center;
          width: 100%;

          @media #{$medium-design} {
            background-size: cover;
          }

          @media #{$medium0-design} {
            width: 397px;
            height: 585px;
          }

          @media #{$medium2-design} {
            width: 520px;
            height: 651px;
          }

          @media #{$medium5-design} {
            width: 500px;
            height: 621px;
          }
        }

        @media #{$medium0-design} {
          float: left;
          margin: 75px 5% 0 90px;
        }

        @media #{$large-design} {
            margin: 75px 80px 0 144px;
        }
      }
      &-text {
        color: $primary-color-site;
        padding: 0;

        .publicis__content {
          padding: 0;
          margin-top: -6px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 21px;
          @extend .common-height;
        }

        @media #{$small-design} {
          height: 268px;
          padding: 0;

          .publicis__content {
            padding: 0 32px;
          }
        }

        @media #{$small0-design} {
          padding: 0;
        }

        @media #{$medium0-design} {
          height: 461px;
          padding: 95px 5% 0 0%;

          .publicis__content {
            padding: 0;
            height: 100%;
            justify-content: flex-start;
          }
        }

        @media #{$medium5-design} {
          padding: 95px 2% 0 0%;
        }

        .publicis{
            &__title{
                margin: 0;
                text-transform: uppercase;
                line-height: 1;

                br {
                  display: none;
                }

                @media #{$medium0-design}{
                    line-height: 0.92;

                    br {
                      display: block;
                    }
                }
                &-normal{
                    letter-spacing: normal;
                    font-size: 60px;

                    @media #{$xsmall-design}{
                        font-size: 70px;
                    }
                    @media #{$medium0-design}{
                        font-size: 32px;
                    }
                    @media #{$medium1-design}{
                        font-size: 44px;
                    }
                    @media #{$medium3-design}{
                        font-size: 48px;
                    }
                    @media #{$medium4-design}{
                        font-size: 58px;
                    }
                    @media #{$medium5-design}{
                        font-size: 70px;
                    }
                }
                &-bold {
                  font-weight: 300;
                  font-size: 60px;

                  @media #{$xsmall-design} {
                    font-size: 65px;
                  }

                  @media #{$medium0-design} {
                    font-size: 28px;
                    padding-top: 8px;
                  }

                  @media #{$medium1-design} {
                    font-size: 38px;
                  }

                  @media #{$medium3-design} {
                    font-size: 40px;
                  }

                  @media #{$medium4-design} {
                    font-size: 50px;
                  }

                  @media #{$medium5-design} {
                    font-size: 65px;
                  }
                }
            }
            &__subtitle{
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: 1.16px;
                margin: 18px 0 0 0;

                @media #{$medium0-design}{
                    margin: 45px 0 0 0;
                }
                @media #{$medium1-design}{
                    line-height: 1;
                    max-width: 1400px;
                }
            }
        }
      }

      @media #{$small-design} { 
        padding-bottom: 266px;
      }
    }
}